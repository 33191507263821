import { createGlobalStyle } from 'styled-components'

import { GothamLight, GothamBook } from 'assets/fonts/Gotham'

import { getColor } from './theme'

export const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    scroll-behavior: smooth;
  }

  ::selection {
    background: #fff;
    opacity: 1;
    color: #232323;
  }

  body {
    background-color: ${getColor('backgroundPrimary')};
    font-family: 'Playfair Display';

    &.scroll-disabled {
      overflow: hidden;
    }
  }

  button {
    outline: 0;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBook}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamLight}) format('opentype');
    font-weight: 300;
    font-style: normal;
  }
`

export const getZIndex = (zIndex) => (props) => props.theme.zIndexes[zIndex]
export const getColor = (color) => (props) => props.theme.colors[color]

export const theme = {
  colors: {
    backgroundPrimary: '#232323',
  },
  zIndexes: {
    header: 100,
    nav: 90,
    tile: 80,
  }
}

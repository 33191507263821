import React from 'react'
import styled, { css } from 'styled-components'

import Heart from 'assets/icons/heart.svg'
import { greaterThan } from 'utils/styles'

const Text = styled.span`
  color: #fff;
  font-family: 'Gotham';
  font-size: 18px;
  margin: 0 15px;
  text-align: center;
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledProjectSlab = styled.a`

background-image: url(${({ imageSrc }) => imageSrc});
background-size: cover;
  background-position: center center;
  flex: 0 0 calc(50% - 3px);
  margin: 1.5px;
  height: 360px;
  height: 100%;
  position: relative;
  text-decoration: none;
  ${({hasGallery}) => hasGallery && css`
    cursor: pointer;
  `}
  &:after {
    ${({ imageHoverSrc }) => imageHoverSrc && css`
      content: '';
      background-image: url(${imageHoverSrc});
      opacity: 0;
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.2s ease-in-out;
    `}
  }


  &:before {
  padding-top: ${({ slabHeight }) => slabHeight || 100}%;
    content: '';
    float: left;
  }

  &:hover:after {
    opacity: 1;
  }

  &:active:after {
    transition: all .2s .2s;
    opacity: 0;
  }

  &:hover ${Overlay} {
    opacity: 1;
  }

  ${greaterThan.medium`
    flex-basis: calc(33.3% - 3px);
  `}
`

export const ProjectSlab = ({ imageSrc, imageHoverSrc, numberOfLikes, text, url, slabHeight, handleGallery, gallery }) => {
  return (
    <StyledProjectSlab
      href={url || undefined}
      imageSrc={imageSrc}
      imageHoverSrc={imageHoverSrc}
      target={url ? '_blank' : undefined}
      slabHeight={slabHeight}
      onClick={() => handleGallery(gallery, 0)}
      hasGallery={!!gallery}
    >
      {(numberOfLikes || text) && (
        <Overlay>
          {numberOfLikes && <Heart />}
          <Text children={numberOfLikes || text} />
        </Overlay>
      )}
    </StyledProjectSlab>
)}

import React, { PureComponent } from 'react'

export class InfiniteScroll extends PureComponent {
  state = {
    page: 1,
  }

  ref = React.createRef()

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll)
  }

  render() {
    const { container } = this.props

    const Container = container

    return (
      <Container ref={this.ref}>
        {this.props.children.slice(0, this.state.page * 12)}
      </Container>
    )
  }

  handleScroll = () => {
    const { hasMore } = this.props
    const container = this.ref.current

    if (hasMore) {
      const distanceFromTop = container.getBoundingClientRect().top + container.offsetHeight
      if (distanceFromTop - 20 < window.innerHeight) {
        if ((this.state.page + 1) * 12 > this.props.children.length) {
          document.removeEventListener('scroll', this.handleScroll)
        }

        this.setState(({ page }) => ({
          page: page + 1,
        }))
      }
    }
  }
}

import { css } from 'styled-components'

const breakpoint = {
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200,
}

export const greaterThan = Object.keys(breakpoint).reduce((acc, key) => {
  acc[key] = (...style) => css`
    @media (min-width: ${breakpoint[key]}px) {
      ${css(...style)}
    }
  `

  return acc
}, {})

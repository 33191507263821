import React from 'react'

import { Layout } from 'components'

export const withDefaultLayout = (Component) => (props) => {
  const { data: { footer }, pageContext } = props

  return (
    <Layout footer={footer} pageContext={pageContext}>
      <Component {...props} />
    </Layout>
  )
}

import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'

import { getZIndex } from 'utils/styles'
import { Container } from 'components/container'

import { Nav } from './nav'
import { Logo } from './logo'

const StyledHeader = styled.div`
  top: 40px;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  z-index: ${getZIndex('header')};
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledLogo = styled(Logo)`
  width: 43px;
  height: 43px;
  cursor: pointer;

  &:hover .a {
    fill: ${({ isNavopen }) => isNavopen ? '#909090' : '#d0d0d0'};
  }

  .a {
    fill: ${({ isNavopen }) => isNavopen ? '#ccc' : '#f5f5f5'};
    transition: fill 0.2s ease-in-out;
  }
`

const MenuButton = styled.button`
  position: relative;
  width: 30px;
  height: 40px;
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;

  &:hover:before,
  &:hover:after {
    background-color: ${({ isnavopen }) => isnavopen ? '#909090' : '#d0d0d0'};
  }
  
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: inherit;
    height: 2px;
    display: block;
    margin: 0 auto;
    background-color: #f5f5f5;
    transition: all 0.3s;
  }
  &:before {
    top: 15px;
    left: 0;
  }
  &:after {
    bottom: 15px;
    left: 0;
  }
  ${({ isnavopen }) => isnavopen && css`
    &:before,
    &:after {
      background-color: #ccc;
    }
    &:before {
      top: calc(50% - 1px);
      transform: rotate(45deg);
    }
    &:after {
      bottom: calc(50% - 1px);
      transform: rotate(-45deg);
    }
  `}
`

export class Header extends PureComponent {
  componentDidMount() {
    // document.addEventListener('mousemove', this.handleMouseMove)

    // this.handleEye()
  }

  state = {
    isNavOpen: false,
    eyeValues: [3, 3],
  }

  x = 3
  y = 3

  render() {
    const { isNavOpen, eyeValues } = this.state
    const { pageContext } = this.props

    return (
      <>
        <Nav
          pageContext={pageContext}
          isOpen={isNavOpen}
          handleClickWhileNavIsOpen={this.handleClickWhileNavIsOpen}
        />

        <StyledHeader onClick={this.handleClickWhileNavIsOpen}>
          <StyledContainer>
            <StyledLogo 
              onClick={this.handleLogoClick}
              eyeValues={eyeValues} isnavopen={isNavOpen ? isNavOpen.toString() : undefined} 
            />
            <MenuButton 
              onClick={this.toggleNav} 
              isnavopen={isNavOpen ? isNavOpen.toString() : undefined} 
            />
          </StyledContainer>
        </StyledHeader>
      </>
    )
  }

  toggleNav = () => {
    this.setState((prevState) => ({
      isNavOpen: !prevState.isNavOpen,
    }))

    // document.body.classList.toggle('scroll-disabled')
  }

  handleLogoClick = () => {
    const { pageContext: { language } } = this.props
    navigate(`/${language === 'en' ? 'en' : ''}`)
  }

  handleClickWhileNavIsOpen = (event) => {
    const { isNavOpen } = this.state

    if (isNavOpen && event.target.tagName !== 'BUTTON') {
      this.toggleNav()
    }
  }

  keep360 = (angle) => {
    return angle < 0
      ? 2 * Math.PI + angle
      : angle
  }

  handleMouseMove = ({ clientX, clientY }) => {
    this.clientX = clientX
    this.clientY = clientY

    this.currentAngle = Math.atan2(this.y, this.x)
    this.targetAngle = Math.atan2(this.clientY - 61, this.clientX - 61)
    this.targetPoint = [Math.cos(this.targetAngle) * 4, Math.sin(this.targetAngle) * 4]

    this.moveVector = Math.atan2(this.clientY - (61 + this.y), this.clientX - (61 + this.x))

    // this.circleMove = Math.abs(this.targetAngle + this.currentAngle) <= Math.PI * 1/2
    this.circleMove = true
    this.direction = this.targetAngle + this.currentAngle < 0 ? -1 : 1
  }

  handleEye = () => {

    if (this.clientX && this.clientY) {

      if (this.circleMove) {

          if ((this.direction === -1 && this.keep360(this.currentAngle) > this.keep360(this.targetAngle))
            || (this.direction === 1 && this.keep360(this.currentAngle) < this.keep360(this.targetAngle))) {

            this.currentAngle += 0.1 * this.direction
            this.x = 4 * Math.cos(this.currentAngle)
            this.y = 4 * Math.sin(this.currentAngle)
            }
        //

      } else {
        if ((this.targetPoint[0] > 0 && this.x <= this.targetPoint[0])
        || (this.targetPoint[0] < 0 && this.x >= this.targetPoint[0]))
        this.x += Math.cos(this.moveVector) * 0.2

        if ((this.targetPoint[1] > 0 && this.y <= this.targetPoint[1])
          || (this.targetPoint[1] < 0 && this.y >= this.targetPoint[1]))
          this.y += Math.sin(this.moveVector) * 0.2
      }

      this.setState({ eyeValues: [this.x, this.y] })
    }
    requestAnimationFrame(this.handleEye)
  }
}

import React from 'react'
import styled from 'styled-components'

import { replaceNToBr } from 'utils/replace-n-to-br'
import { Container } from 'components/container'
import Facebook from 'assets/icons/facebook.svg'
import Instagram from 'assets/icons/instagram.svg'

const StyledContactTile = styled(Container)`
  padding: 50px 0;
`

const Heading = styled.h2`
  color: #fff;
  font-size: 36px;
  font-weight: 400;
`

const AddressesContainer = styled.div`
  margin-top: 50px;
`

const Address = styled.address`
  color: #616161;
  font-style: normal;
  font-family: 'Gotham';
  line-height: 24px;
  font-size: 12px;

  a {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #fff;
    }
  }

  &:nth-child(n+1) {
    margin-top: 50px;
  }
`

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`

const CallToAction = styled.a`
  color: #616161;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
`

const SocialIcon = styled.a`
  &:nth-child(n+1) {
    margin-left: 25px;
  }

  svg .a {
    transition: fill 0.2s ease-in-out;
  }

  svg:hover .a {
    fill: #fff;
  }
`

const LogoContainer = styled.div`
  margin-top: 50px;
  width: 231px;
  height: 230px;
  background-image: url('/pfr-logo-mobile.png');
  background-position: center;
  background-size: cover;
  @media(min-width: 768px) {
    background-image: url('/pfr-logo-desktop.png');
    width: 700px;
    height: 106px;
  }
`;

export const ContactTile = ({ footer: {
    heading,
    firstAddress: { firstAddress },
    secondAddress: { secondAddress },
    writeToMe,
    facebookUrl,
    instagramUrl,
} }) => (
  <div id='contact' >
    <StyledContactTile as="footer">
      <Heading>{heading}</Heading>

      <AddressesContainer>
        <Address dangerouslySetInnerHTML={{ __html: replaceNToBr(firstAddress) }} />
        <Address dangerouslySetInnerHTML={{ __html: replaceNToBr(secondAddress) }} />
      </AddressesContainer>

      <Bar>
        <CallToAction href="mailto: michal@korolec.pl">{writeToMe}</CallToAction>

        <div>
          <SocialIcon href={facebookUrl} target="_blank"><Facebook /></SocialIcon>
          <SocialIcon href={instagramUrl} target="_blank"><Instagram /></SocialIcon>
        </div>
      </Bar>
      <LogoContainer />
    </StyledContactTile>
  </div>
)
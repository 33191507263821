import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { getZIndex } from 'utils/styles'

import { Container } from './container'

const StyledNav = styled.nav`
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: ${getZIndex('nav')};

  ${({ isOpen }) => isOpen && css`
    opacity: 1;
    visibility: visible;
  `}
`

const NavLinkStyles = css`
  font-size: 42px;
  color: #E2E2E2;
  line-height: 56px;
  text-decoration: none;
  text-align: right;
  transition: 0.2s ease-in-out;

  &:hover {
    color: #232323;
  }
`

const NavLink = styled(Link)`
  ${NavLinkStyles}
`

const NonFunctionalNavLink = styled.a`
  ${NavLinkStyles}
`

const LinksContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const LanguageSwitcher = styled(Link)`
  color: #E2E2E2;
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none;
  position: absolute;
  bottom: 40px;
  align-self: flex-end;

  &:hover {
    color: #232323;
  }
`

export class Nav extends PureComponent {
  render() {
    const { isOpen = false, handleClickWhileNavIsOpen, pageContext: { language } } = this.props

    const addPrefixToPath = (path) => language === 'en'
      ? `/en${path}`
      : path

    return (
      <StyledNav isOpen={isOpen} onClick={handleClickWhileNavIsOpen}>
        <LinksContainer>

          <NavLink
            to={addPrefixToPath('/about')}
            children={this.translate('about')}
          />

          <NavLink
            to={addPrefixToPath('/#projects')}
            children={this.translate('myWork')}
          />

          <NavLink
            to={addPrefixToPath('/logotypes')}
            children={this.translate('logotypes')}
          />

          <NonFunctionalNavLink
            href="/PORTFOLIO_2019.pdf"
            children={this.translate('portfolio')}
            target="_blank"
          />

          <NavLink
            to={addPrefixToPath('/#contact')}
            children={this.translate('contact')}
          />

          <LanguageSwitcher to={this.getOppositeLanguageLink()}>
            {language === 'en' ? 'Przejdź do wersji polskiej' : 'Switch to English version'}
          </LanguageSwitcher>
        </LinksContainer>
      </StyledNav>
    )
  }

  getOppositeLanguageLink = () => {
    const { language, originalPath } = this.props.pageContext
    return language === 'en' ? originalPath : `/en${originalPath}`
  }

  translate = (node) => {
    const { pageContext: { language } } = this.props

    const translations = {
      en: {
        about: 'About me',
        logotypes: 'Logotypes',
        myWork: 'My work',
        contact: 'Contact',
        portfolio: 'Portfolio',
      },

      pl: {
        about: 'O mnie',
        logotypes: 'Logotypy',
        myWork: 'Moje prace',
        contact: 'Kontakt',
        portfolio: 'Portfolio',
      }
    }

    return translations[language][node]
  }
}

import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { Container } from './container'
import { ProjectSlab } from './project-slab'
import { InfiniteScroll } from './infinite-scroll'

const StyledProjectsTile = styled.div`
  padding: 120px 0 20px;
  overflow-x: hidden;
`

const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1500px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`

const Expander = styled.a`
  margin-top: 60px;
  display: block;
  color: #616161;
  transition: color 0.2s ease;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
`

export class ProjectsTile extends PureComponent {
  state = {
    hasMore: false,
  }

  render() {
    const { language, projects, slabHeight, withInfiniteScroll, instagramLink, handleGallery } = this.props

    const mappedProjects = projects.map(((project, index) =>
      <ProjectSlab
        key={project.id || index}
        name={project.name}
        gallery={project.gallery}
        imageSrc={project.image}
        url={project.url}
        imageHoverSrc={project.imageHover}
        numberOfLikes={project.numberOfLikes}
        text={project.text}
        slabHeight={slabHeight}
        handleGallery={handleGallery}
      />
    ))
    return (
      <StyledProjectsTile>

        {withInfiniteScroll ? (
          <InfiniteScroll container={ProjectsContainer} hasMore={true}>
            {mappedProjects}
          </InfiniteScroll>
        ) : (
          <ProjectsContainer>
            {mappedProjects}
          </ProjectsContainer>
        )}

        {instagramLink && (
          <Container>
            <Expander
              href="https://www.instagram.com/michal_korolec/"
              target="_blank"
            >
              {language === 'pl' ? 'Kliknij tutaj, aby zobaczyć więcej' : 'Click here to see more'}
            </Expander>
          </Container>
        )}
      </StyledProjectsTile>
    )
  }

  handleExpanderClick = () => {
    this.expanderUsed = true

    this.setState(({
      hasMore: true,
      loadSecondPage: true,
    }))
  }
}

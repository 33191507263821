import React from 'react'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import Helmet from 'react-helmet'

import { GlobalStyles, theme } from 'utils/styles'
import favicon32 from 'assets/images/favicon-32x32.png'
import favicon16 from 'assets/images/favicon-16x16.png'

import { Header } from './header'
import { ContactTile } from './contact-tile'

export const Layout = ({ children, pageContext, footer }) => (
  <ThemeProvider theme={theme}>
    <>
      <Helmet>
        <title>Michał Korolec - niezależne studio projektowe</title>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <meta name="keywords" content="studio designu, projektowanie, logotyp" />
        <meta name="description" content="Niezależne studio projektowe założone w 2013 roku przez Michała Korolca. Specjalizujemy się w komunikacji wizualnej." />
      </Helmet>
      <Normalize />
      <GlobalStyles />
      <Header pageContext={pageContext} />

      {children}

      <ContactTile footer={footer} />
    </>
  </ThemeProvider>
)
